exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-items-js": () => import("./../../../src/pages/lost-items.js" /* webpackChunkName: "component---src-pages-lost-items-js" */),
  "component---src-pages-pets-albus-js": () => import("./../../../src/pages/pets/albus.js" /* webpackChunkName: "component---src-pages-pets-albus-js" */),
  "component---src-pages-pets-ginny-js": () => import("./../../../src/pages/pets/ginny.js" /* webpackChunkName: "component---src-pages-pets-ginny-js" */),
  "component---src-pages-pets-molly-js": () => import("./../../../src/pages/pets/molly.js" /* webpackChunkName: "component---src-pages-pets-molly-js" */),
  "component---src-pages-pets-myrtle-js": () => import("./../../../src/pages/pets/myrtle.js" /* webpackChunkName: "component---src-pages-pets-myrtle-js" */)
}

